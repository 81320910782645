@import url('https://fonts.googleapis.com/css2?family=Sora:wght@100..800&display=swap');
html,
body,
#app,
#root,
#app > div {
  height: 100%;
}

body > iframe {
  display: none;
}

#extras {
  position: absolute;
  inset: 0;
  overflow: scroll;
  z-index: 1;

  &.withHeader {
    top: 64px;
  }

  &:empty {
    display: none;
  }
}
